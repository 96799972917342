import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "constants",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#constants",
        "aria-label": "constants permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Constants`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#sdkenvs"
        }}>{`sdk.ENVS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#sdkfunders"
        }}>{`sdk.FUNDERS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#sdkmedicarereferrertypes"
        }}>{`sdk.MEDICARE.REFERRER_TYPES`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#sdkmedicarereferralperiods"
        }}>{`sdk.MEDICARE.REFERRAL_PERIODS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#sdkhealthfunds"
        }}>{`sdk.HEALTH_FUNDS`}</a></li>
    </ul>
    <h3 {...{
      "id": "sdkenvs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sdkenvs",
        "aria-label": "sdkenvs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`sdk.ENVS`}</h3>
    <blockquote>
      <p parentName="blockquote">{`returns `}<inlineCode parentName="p">{`Object`}</inlineCode></p>
    </blockquote>
    <p>{`Gets the available environments for the SDK.`}</p>
    <h3 {...{
      "id": "sdkfunders",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sdkfunders",
        "aria-label": "sdkfunders permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`sdk.FUNDERS`}</h3>
    <blockquote>
      <p parentName="blockquote">{`returns `}<inlineCode parentName="p">{`Object`}</inlineCode></p>
    </blockquote>
    <p>{`Gets the available funders that can be claimed against in the SDK.`}</p>
    <h3 {...{
      "id": "sdkmedicarereferrer_types",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sdkmedicarereferrer_types",
        "aria-label": "sdkmedicarereferrer_types permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`sdk.MEDICARE.REFERRER_TYPES`}</h3>
    <blockquote>
      <p parentName="blockquote">{`returns `}<inlineCode parentName="p">{`Object`}</inlineCode></p>
    </blockquote>
    <p>{`Gets the available referrer types for a Medicare claim.`}</p>
    <h3 {...{
      "id": "sdkmedicarereferral_periods",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sdkmedicarereferral_periods",
        "aria-label": "sdkmedicarereferral_periods permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`sdk.MEDICARE.REFERRAL_PERIODS`}</h3>
    <blockquote>
      <p parentName="blockquote">{`returns `}<inlineCode parentName="p">{`Object`}</inlineCode></p>
    </blockquote>
    <p>{`Gets the available referral periods for a Medicare claim.`}</p>
    <h3 {...{
      "id": "sdkhealth_funds",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sdkhealth_funds",
        "aria-label": "sdkhealth_funds permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`sdk.HEALTH_FUNDS`}</h3>
    <blockquote>
      <p parentName="blockquote">{`returns `}<inlineCode parentName="p">{`Object`}</inlineCode></p>
    </blockquote>
    <p>{`Gets a list of all supported HICAPS health funds`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      